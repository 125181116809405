.background {
    background-color: #473c41 !important;
}

.theme-colour {
    background-color: #ffc350;
}

.card {
    height : 31.5vh;
}

.container {
    margin-top: 2%;
}

.title {
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin-left: 1.5%;
}

.name-heading {
    font-size: 175%;
    font-weight: 400;
    margin-bottom: 2% !important;
}

.input-field {
    border : 1px solid black !important;
    border-radius: 6px !important;
    padding-left: 2% !important;
    width: 100% !important;
    height: 4vh !important;
    box-shadow: none !important;
}

::placeholder {
    color: black;

  }

.span-input-field {
    margin-right: 2%;
}

@media only screen and (max-width: 400px) {
    img {
        display: block !important;
        width: 100% !important;
    }
}